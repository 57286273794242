/* Reset button styles to remove unwanted backgrounds or borders */
button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none;
}

button img {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
}

/* Storyboard container */
.storyboard-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  background-color: #1b1b3a;
  overflow: hidden;
}

/* Scene image background */
.scene-image {
  position: relative;
  width: 100vw;
  height: 60vh; /* Reduced height for more text box space */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
}

/* Chapter title styling */
.chapter-title {
  color: rgb(255, 255, 255);
  font-size: 7vw; /* Increased font size */
  margin: 10;
  flex-grow: 1;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

/* Back button */
.back-button img {
  position: absolute;
  top: 2vw;
  left: 2vw;
  width: 8vw;
  height: auto;
  z-index: 30;
  cursor: pointer;
}

/* Bookmark button */
.bookmark-button img {
  position: absolute;
  top: 7vw;
  right: 7vw;
  width: 8vw;
  height: auto;
  z-index: 10;
  cursor: pointer;
}

/* Previous button */
.previous-button img {
  position: absolute;
  bottom: 3.3vh;
  left: 14vw;
  width: 15vw;
  height: auto;
  z-index: 10;
  cursor: pointer;
}

/* Play/Pause button */
.play-button img {
  position: absolute;
  bottom: 2.5vh;
  left: 40vw;
  width: 20vw;
  height: auto;
  z-index: 10;
  cursor: pointer;
}

/* Next button */
.next-button img {
  position: absolute;
  bottom: 3.5vh;
  left: 70vw;
  width: 15vw;
  height: auto;
  z-index: 10;
  cursor: pointer;
}

/* Story Text Box */
.story-text-box {
  position: absolute;
  bottom: -320px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
  height: 45vh;  /* Increased height for more space */
  background-color: rgba(20, 24, 40, 0.8); /* Darker background */
  border-radius: 25px 25px 0 0; /* Smoother corners */
  padding: 4vw;
  font-size: 5vw; /* Increased font size */
  overflow-y: auto;
  color: rgba(255, 255, 255, 0.9); /* White text with slight opacity */
  font-family: 'Arial', sans-serif;
  z-index: 5;
  backdrop-filter: blur(10px); /* Smooth blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari compatibility */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: backdrop-filter 0.3s ease, box-shadow 0.3s ease;
}

/* Highlighted words with a smooth fade transition and rounded corners */
.highlighted {
  background-color: rgba(127, 0, 255, 0.6); /* Purple highlight color */
  color: white; /* Text color when highlighted */
  border-radius: 5px; /* Rounded corners */
  padding: 0.2em 0.4em; /* Adds some padding around words for visual balance */
  
  /* Add a smooth transition effect for highlighting */
  transition: background-color 0.5s ease, color 0.5s ease, border-radius 0.3s ease;
}