@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');

/* Container Styling */
.formpage-container {
    opacity: 0;
    transition: opacity 2s ease-in-out;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    font-family: 'Fredoka One', sans-serif;
}

.fade-in {
    opacity: 1;
}

/* Background Styling */
.form-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform: rotate(0deg);
}

/* Back Arrow Styling */
.back-arrow {
    position: absolute;
    top: 30px;
    left: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    z-index: 10;
}

.back-arrow svg {
    width: 30px;
    height: 30px;
    stroke: #ffffff;
    transition: stroke 0.3s ease;
}

.back-arrow svg:hover {
    stroke: #80deea;
}

/* Form Box Styling */
.form-box {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 600px;
    padding: 25px;
    background-color: rgba(0, 0, 0, 0.35); /* Make the box more transparent */
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 2;
    animation: formGlow 3s infinite alternate;
}

/* Glowing Animation for the Form Box */
@keyframes formGlow {
    from {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
        border-color: rgba(255, 255, 255, 0.5);
    }
    to {
        box-shadow: 0 0 40px rgba(255, 255, 255, 1);
        border-color: rgba(255, 255, 255, 1);
    }
}

/* Form Navigation Styling */
.form-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

/* Back Button Styling */
.form-back-button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 35px;
    cursor: pointer;
    padding: 0;
    outline: none;
    transition: transform 0.3s ease;
}

.form-back-button:hover {
    transform: scale(1.2);
}

/* Question Styling with Lato Thin and Glow */
.form-question {
    font-family: 'Lato', sans-serif; /* Lato thin for category titles */
    font-weight: 100; /* Thin font weight */
    font-size: 28px;
    text-align: center;
    margin: 0 auto;
    color: #ffffff;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Faint white glow */
}

/* Input Styling */
.form-input {
    width: 100%;
    padding: 12px;
    font-size: 18px;
    border-radius: 30px;
    border: none;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.form-input::placeholder {
    color: rgba(223, 223, 223, 0.8); /* Adjust the brightness */
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Optional: add glow effect */
}

.form-input:focus {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    outline: none;
}

/* Select Styling */
.form-select {
    width: 100%;
    padding: 12px;
    font-size: 18px;
    border-radius: 30px;
    border: none;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.form-select:focus {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    outline: none;
}

/* Form Buttons Container */
.form-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Next/Generate Button Styling */
.form-next-button {
    padding: 14px 28px;
    font-size: 18px;
    background: linear-gradient(90deg, #799b86, #ccaea0);
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.4);
    position: relative;
    margin: 0 auto;
}

.form-next-button:hover {
    background: linear-gradient(90deg, #799b86, #ccaea0);
    transform: scale(1.05);
}

/* Generate Button Styling */
.generate-button {
    background: linear-gradient(90deg, #6c5ce7, #a29bfe);
    box-shadow: 0 4px 12px rgba(108, 92, 231, 0.4);
}

.generate-button:hover {
    background: linear-gradient(90deg, #4834d4, #6c5ce7);
}

/* Advanced Button Styling */
.settings-button {
    background: none;
    border: none;
    color: white;
    font-size: 14px;
    text-transform: capitalize;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    transition: text-shadow 0.3s ease;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.9); /* Soft glow effect */
    position: absolute;
    right: 10px;
    bottom: 5px;
}

.settings-button:hover {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1);
}

/* Sidebar Styling */
.sidebar {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.5);
    z-index: 3;
    transition: right 0.3s ease;
}

.sidebar.open {
    right: 0;
}

/* Sidebar Toggle Button */
.toggle-sidebar {
    position: absolute;
    left: -80px;
    top: 30px;
    width: 80px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.9); /* Soft white glow */
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}

.toggle-sidebar:hover {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 40px rgba(255, 255, 255, 1);
    text-shadow: 0 0 20px rgba(255, 255, 255, 1);
}

/* Sidebar Content with Lato Thin Text and Glow */
.sidebar-content {
    padding: 20px;
    color: #fff;
    font-family: 'Lato', sans-serif; /* Lato thin for the summary */
    font-weight: 100; /* Thin font weight */
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Faint white glow */
}

.sidebar-content h3 {
    margin-top: 0;
    text-align: center;
}

.sidebar-content ul {
    list-style-type: none;
    padding: 0;
}

.sidebar-content li {
    margin-bottom: 20px;
    font-size: 16px;
}


