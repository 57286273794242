/* Root variables for easy customization */
:root {
  --brightness: 100%; /* Adjusts the brightness of the background image */
  --bg-color: transparent; /* Background color of the body */
  --text-color: #ffffff; /* Main text color throughout the page */
  --primary-color: #ffffff; /* Primary color for elements */
  --accent-color: #ff7675; /* Accent color for highlighting elements */

  --container-width: 100%; /* Width of the main container */
  --max-content-width: 550px; /* Maximum width for the content */
  --element-spacing: 20px; /* General spacing between elements */
  --title-font-size: 28px; /* Font size for main titles */
  --section-title-font-size: 20px; /* Font size for section titles */
  --input-font-size: 16px; /* Font size for input fields and smaller text */
}

/* Use Lato thin font */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');

/* General reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Body background color */
body {
  background-color: var(--bg-color); /* Set the background color */
}

/* Background image styling */
.settings-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Makes the image cover the entire screen */
  z-index: 1; /* Places it behind all other content */
}

/* Overlay for dropdowns */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Adds a dark overlay */
  z-index: 2; /* Sits above the background but below other content */
}

/* Back Button */
.back-button {
  position: fixed;
  top: 20px; /* Distance from the top of the viewport */
  left: 20px; /* Distance from the left of the viewport */
  background: none; /* No background */
  border: none; /* No border */
  color: var(--text-color); /* Uses the main text color */
  font-size: 30px; /* Size of the back button text/icon */
  cursor: pointer; /* Changes cursor to pointer on hover */
  z-index: 3; /* Places it above other elements */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Adds a glow effect */
}

/* Container */
.homesettings-container {
  position: relative;
  width: var(--container-width);
  margin: 0 auto;
  padding: calc(var(--element-spacing) * 3) var(--element-spacing); /* Adjust the first value to add top padding */
  min-height: 85vh;
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  background: none;
}

/* Title */
.settings-title {
  font-size: var(--title-font-size); /* Title font size */
  color: var(--text-color) !important; /* Uses the main text color */
  margin-bottom: calc(var(--element-spacing) * 2); /* Spacing below the title */
  text-align: center; /* Centers the title text */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Adds a glow effect */
}

/* Settings Section */
.settings-section {
  width: 100%; /* Full width of the container */
  margin-bottom: var(--element-spacing); /* Spacing between sections */
  display: flex;
  flex-direction: column; /* Arranges content in a column */
  align-items: center; /* Centers content */
  background: none; /* No background color */
}

.settings-section h3 {
  font-size: var(--section-title-font-size); /* Section title font size */
  margin-bottom: 10px; /* Spacing below the section title */
  text-align: center; /* Centers the section title */
  color: var(--text-color); /* Uses the main text color */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Adds a glow effect */
}

/* Selected option display */
.selected-option {
  font-size: 14px; /* Font size of the selected option text */
  color: var(--text-color); /* Uses the main text color */
  text-align: center; /* Centers the text */
  margin-bottom: 10px; /* Spacing below the option text */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Adds a glow effect */
}

/* Dropdown styling */
.dropdown {
  position: fixed;
  top: 50%; /* Centers dropdown vertically */
  left: 50%; /* Centers dropdown horizontally */
  transform: translate(-50%, -50%); /* Offsets to perfectly center */
  background: none; /* No background color */
  color: var(--text-color); /* Uses the main text color */
  text-align: center; /* Centers the dropdown content */
  z-index: 5; /* Places above the overlay */
  font-family: 'Lato', sans-serif; /* Font for dropdown */
  font-weight: 100; /* Thin font weight */
}

.dropdown p {
  cursor: pointer; /* Changes cursor to pointer on hover */
  margin: 15px 0; /* Spacing between dropdown options */
  font-size: 22px; /* Font size for dropdown options */
  color: var(--text-color); /* Uses the main text color */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Adds a glow effect */
}

.dropdown p:hover {
  text-decoration: underline; /* Underlines the text on hover */
}

.dropdown p:active {
  opacity: 0.8; /* Lowers opacity on click */
}

/* Save button within dropdown */
.dropdown-save-button {
  margin-top: 15px; /* Spacing above the save button */
  padding: 10px; /* Padding inside the button */
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent background */
  border: 1px solid var(--text-color); /* Border color */
  border-radius: 8px; /* Rounded corners */
  color: var(--text-color); /* Uses the main text color */
  cursor: pointer; /* Changes cursor to pointer on hover */
  width: 120px; /* Width of the button */
  font-size: 18px; /* Font size of the button text */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Adds a glow effect */
}

.dropdown-save-button:hover {
  background: rgba(255, 255, 255, 0.5); /* Changes background on hover */
}

/* Range Slider for Brightness */
.settings-range {
  width: 100%; /* Full width */
  max-width: 300px; /* Maximum width of the slider */
}

.settings-range::-webkit-slider-thumb {
  background-color: var(--text-color); /* Color of the slider thumb */
}

.settings-range::-moz-range-thumb {
  background-color: var(--text-color); /* Color for Firefox */
}

.settings-range::-ms-thumb {
  background-color: var(--text-color); /* Color for Internet Explorer */
}

/* Textarea for feedback */
.settings-textarea {
  width: 100%; /* Full width of the container */
  max-width: 300px; /* Maximum width */
  padding: 12px; /* Padding inside the textarea */
  border-radius: 8px; /* Rounded corners */
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
  color: var(--text-color); /* Text color */
  font-size: var(--input-font-size); /* Font size for text */
  resize: vertical; /* Allows vertical resizing */
  min-height: 100px; /* Minimum height of the textarea */
  border: 1px solid var(--text-color); /* Border color */
}

.settings-textarea::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Placeholder text color */
}

/* Submit Feedback Button */
.submit-feedback-button.show {
  margin-top: 10px; /* Spacing above the button */
}

.submit-feedback-button button {
  padding: 4px; /* Padding inside the button */
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent background */
  border: 1px solid var(--text-color); /* Border color */
  border-radius: 10px; /* Rounded corners */
  color: var(--text-color); /* Button text color */
  cursor: pointer; /* Changes cursor to pointer on hover */
  width: 100%; /* Full width of the container */
  max-width: 300px; /* Maximum button width */
  font-size: var(--input-font-size); /* Button text size */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7); /* Adds a glow effect */
}

.submit-feedback-button button:hover {
  background: rgba(255, 255, 255, 0.5); /* Changes background on hover */
}

/* Save Settings Button */
.save-settings {
  margin-top: 40px; /* Spacing above the save button */
  width: 100%; /* Full width of the container */
  max-width: 300px; /* Maximum button width */
}

.save-settings button {
  width: 100%; /* Full width of the container */
  padding: 14px; /* Padding inside the button */
  background: rgba(255, 255, 255, 0.3); /* Semi-transparent background */
  border: 1px solid var(--text-color); /* Border color */
  border-radius: 8px; /* Rounded corners */
  color: var(--text-color); /* Button text color */
  font-size: 18px; /* Button text size */
  cursor: pointer; /* Changes cursor to pointer on hover */
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Adds a glow effect */
}

.save-settings button:hover {
  background: rgba(255, 255, 255, 0.5); /* Changes background on hover */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .settings-title {
    font-size: 24px; /* Adjust title size for smaller screens */
  }

  .settings-section h3 {
    font-size: 18px; /* Adjust section title size for smaller screens */
  }

  .save-settings button {
    font-size: 16px; /* Adjust button text size for smaller screens */
  }
}
