/* Import Lato thin font */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');

/* General reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Background image styling */
.dashboard-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  filter: brightness(100%);
}

/* Container */
.dashboard-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 60px 20px; /* Adjust top padding to move content down */
  min-height: 85vh;
  font-family: 'Lato', sans-serif;
  font-weight: 100; /* Lato Thin */
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  background: none;
}

/* Story History Header */
.story-history-header {
  font-size: 36px; /* Larger font size for page header */
  color: #ffffff;
  margin-bottom: 30px;
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Favorites Title */
.favorites-title {
  font-size: 28px; /* Smaller than Story History */
  color: #ffffff;
  margin-top: 60px; /* Adjusted to move "Favorites" down */
  margin-bottom: 10px; /* Space below "Favorites" */
  text-align: center;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid #ffffff; /* Thin white line below the title */
  width: 80%; /* Adjust width of the underline */
  padding-bottom: 10px; /* Spacing between the line and story titles */
}

/* Story List */
.story-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:-5px; /* Reduced spacing between story items */
}

/* Story Item */
.story-item {
  width: 100%;
  margin-bottom: -20px; /* Reduced spacing below each story item */
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  cursor: pointer; /* Change cursor to pointer to indicate clickable items */
}

/* Story Title */
.story-title {
  font-size: 22px;
  margin-bottom: 10px;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Buttons */
.view-all-button,
.create-story-button {
  margin-top: 50px;
  width: 100%;
  max-width: 350px;
  padding: 14px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  transition: background 0.3s ease;
}

.view-all-button:hover,
.create-story-button:hover {
  background: rgba(255, 255, 255, 0.5);
}

/* Back Button */
.back-button {
  position: fixed;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
  z-index: 3;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Overlay */
.story-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Overlay Content */
.overlay-content {
  background: none;
  color: #ffffff;
  text-align: center;
  z-index: 5;
  font-family: 'Lato', sans-serif;
  font-weight: 100;
  max-height: 80vh;
  overflow-y: auto;
}

/* Overlay Story Item */
.overlay-story-item {
  cursor: pointer;
  margin: 15px 0;
  font-size: 22px;
  color: #ffffff;
  line-height: 1.5;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.overlay-story-item:hover {
  text-decoration: underline;
}

.overlay-story-item:active {
  opacity: 0.8;
}

/* Close Button */
.overlay-back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  border: none;
  color: #ffffff;
  font-size: 24px;
  cursor: pointer;
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

/* Responsive Design */
@media (max-width: 768px) {
  .story-history-header {
    font-size: 24px;
  }

  .story-title {
    font-size: 20px;
  }

  .view-all-button,
  .create-story-button {
    font-size: 16px;
    padding: 12px;
  }
}
