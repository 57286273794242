/* Global Styles */
body, html {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    background: #1b1b3a;
    color: var(--text-color);
}

.story-page {
    width: 100%;
    min-height: 100vh;
    overflow-y: auto;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Background Image */
.story-background {
    position: fixed;
    top: 0; /* Adjust this value to move the image up */
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

/* Loading Screen Styles */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: relative;
    text-align: center;
}

/* Full-screen loading image */
.loading-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: 1;
}

/* Loading Bar Container */
.loading-bar-container {
    position: absolute;
    top: 43%; /* Adjust this to control how high the loading bar starts */
    left: 50%;
    transform: translateX(-50%);
    width: 85px; /* Adjust this to make the bar thicker */
    height: 51%; /* Adjust this to control the bar's length */
    background-color: rgba(255, 255, 255, 0.3);
    overflow: hidden;
    z-index: 2;
}

/* Loading Bar - Tapered Shape */
.loading-bar {
    position: absolute;
    top: 0;
    width: 100%;
    height: 0%; /* Initial height */
    background: linear-gradient(45deg, #fce6c5, #faf2db); /* Gradient color */
    box-shadow: 0px 0px 15px rgba(255, 165, 0, 0.5); /* Outer glow effect */
    transition: height 0.5s ease;
    clip-path: polygon(50% 100%, 100% 0%, 0% 0%); /* Adjust this for a more pronounced tapering */
}

/* Optional Animation for Flame Effect */
@keyframes loadingFlame {
    0%, 100% {
        transform: scaleX(1) scaleY(1);
    }
    50% {
        transform: scaleX(1.1) scaleY(1.1);
    }
}

.loading-bar {
    animation: loadingFlame 1s infinite ease-in-out;
}


/* Loading text */
.loading-text {
    position: absolute;
    bottom: 93%; /* Adjust this to position the text higher */
    width: 100%;
    text-align: center;
    font-size: 22px;
    color: var(--text-color);
    z-index: 2;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Story Container */
.story-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    min-height: 85vh;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    background: rgba(27, 27, 58, 0);
    border-radius: 10px;
    box-shadow: 0 5 20px rgba(0, 0, 0, 0.3);
}

/* Story Title */
.story-title {
    font-size: 28px;
    color: var(--text-color) !important;
    margin-bottom: 40px;
    text-align: center;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Story Summary */
.story-summary {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
}

.summary-text {
    font-size: 16px;
    line-height: 1.5;
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 10px;
    color: var(--text-color);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Story Content */
.story-content {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 40px;
    text-align: left;
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    color: var(--text-color);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Controls */
.controls {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
}

.control-button {
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid var(--text-color);
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
}

.control-button:hover {
    background: rgba(255, 255, 255, 0.5);
    transform: scale(1.1);
}

.control-button img {
    width: 30px;
    height: 30px;
    filter: brightness(0) invert(1);
}

/* Action Buttons */
.action-buttons {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.save-button, .generate-another-button {
    margin-top: 40px;
    width: 100%;
    max-width: 300px;
    padding: 14px;
    background: rgba(255, 255, 255, 0.219);
    border: 0px solid var(--text-color);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 18px;
    cursor: pointer;
    text-shadow: 5 0 20px rgb(255, 255, 255);
    transition: background 0.3s ease;
}

.save-button:hover, .generate-another-button:hover {
    background: rgba(255, 255, 255, 0);
}

/* Story ID Display */
.story-id {
    font-size: 14px;
    color: var(--text-color);
    margin-top: 10px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

/* Responsive Design */
@media (max-width: 768px) {
    .story-title {
        font-size: 24px;
    }

    .summary-text, .story-content {
        font-size: 20px;
    }

    .control-button img {
        width: 25px;
        height: 25px;
    }

    .save-button, .generate-another-button {
        font-size: 16px;
        padding: 12px;
    }
}
