.screenone-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.wizard {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 175px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer; /* Ensure the wizard image is clickable */
}

.staff, .sparks {
    cursor: pointer;
}

/* The rest of your CSS stays the same */
.staff {
    position: absolute;
    top: 51%;
    left: calc(50% + -79px);
    transform: translateY(-50%);
    z-index: 3;
    width: 165px;
    height: auto;
    transition: transform 0.5s ease-in-out;
}

/* Animation for the staff movement */
@keyframes staff-animate {
    0% { transform: translateY(-50%); }
    25% { transform: translateY(-55%); } /* Lower the staff less */
    50% { transform: translateY(-50%); }
    75% { transform: translateY(-55%); } /* Lower the staff less */
    100% { transform: translateY(-50%); }
}

.animate-staff {
    animation: staff-animate 1.5s ease-in-out;
}

.sparks {
    position: absolute;
    top: 52%;
    left: calc(50% + -113px);
    transform: translateY(-50%);
    z-index: 4;
    width: 200px;
    height: auto;
    display: none; /* Hide sparks by default */
}

.show-sparks {
    animation: sparks-appear 1.5s ease-in-out;
    display: block;
}

@keyframes sparks-appear {
    0%, 25%, 75%, 100% { opacity: 0; }
    50% { opacity: 1; } /* Sparks appear only when staff is at bottom */
}

.hide-sparks {
    display: none; /* Hide sparks when not animating */
}

.env-indicator {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}










