/* Root variables for easy customization */
:root {
    --brightness: 100%;
    --bg-color: transparent;
    --text-color: #ffffff;
    --primary-color: #ffffff;
    --accent-color: #ff7675;
  
    --container-width: 100%;
    --max-content-width: 550px;
    --element-spacing: 20px;
    --title-font-size: 28px;
    --section-title-font-size: 20px;
    --input-font-size: 16px;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap');
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    background-color: var(--bg-color);
  }
  
  .settings-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 2;
  }
  
  .back-button.hidden,
  .settings-container.hidden {
    display: none;
  }
  
  .back-button {
    position: fixed;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 30px;
    cursor: pointer;
    z-index: 3;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  
  .settings-container {
    position: relative;
    width: var(--container-width);
    max-width: var(--max-content-width);
    margin: 0 auto;
    padding: var(--element-spacing);
    min-height: 85vh;
    font-family: 'Lato', sans-serif;
    font-weight: 100;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: none;
  }
  
  .settings-title {
    font-size: var(--title-font-size);
    color: var(--text-color) !important;
    margin-bottom: calc(var(--element-spacing) * 2);
    text-align: center;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  
  .settings-section {
    width: 100%;
    margin-bottom: var(--element-spacing);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
  }
  
  .settings-section h3 {
    font-size: var(--section-title-font-size);
    margin-bottom: 10px;
    text-align: center;
    color: var(--text-color);
    cursor: pointer;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  
  .selected-option {
    font-size: 14px;
    color: var(--text-color);
    text-align: center;
    margin-bottom: 10px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  
  .dropdown {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
    color: var(--text-color);
    text-align: center;
    z-index: 6; /* Ensure it is above the overlay */
    font-family: 'Lato', sans-serif;
    font-weight: 100;
  }
  
  .dropdown p {
    cursor: pointer;
    margin: 15px 0;
    font-size: 22px;
    color: var(--text-color);
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  
  .dropdown p:hover {
    text-decoration: underline;
  }
  
  .dropdown p:active {
    opacity: 0.8;
  }
  
  .dropdown-save-button {
    margin-top: 20px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid var(--text-color);
    border-radius: 8px;
    color: var(--text-color);
    cursor: pointer;
    font-size: 18px;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  
  .dropdown-save-button:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  
  .save-settings {
    margin-top: 40px;
    width: 100%;
    max-width: 300px;
  }
  
  .save-settings button {
    width: 100%;
    padding: 14px;
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid var(--text-color);
    border-radius: 8px;
    color: var(--text-color);
    font-size: 18px;
    cursor: pointer;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  }
  
  .save-settings button:hover {
    background: rgba(255, 255, 255, 0.5);
  }
  
  @media (max-width: 768px) {
    .settings-title {
      font-size: 24px;
    }
  
    .settings-section h3 {
      font-size: 18px;
    }
  
    .save-settings button {
      font-size: 16px;
    }
  }
  
  
  