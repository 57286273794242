.homepage-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    transition: opacity 1s ease-in-out;
}

/* Home Background Image */
.home-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

/* Add glow effect to the icons */
.home-settings, .home-history, .home-book {
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.7)); /* Adds glow effect */
    transition: transform 0.3s ease, filter 0.3s ease;
}

/* On hover, increase the glow and slightly enlarge the icons */
.home-settings:hover, .home-history:hover, .home-book:hover {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 1));
    transform: scale(1.1);
}

/* Home Settings Icon */
.home-settings {
    position: absolute;
    bottom: 40px;
    left: 78%;
    width: 35px;
    height: auto;
    z-index: 3;
}

/* Home History Icon */
.home-history {
    position: absolute;
    bottom: 43px;
    right: 78%;
    width: 35px;
    height: auto;
    z-index: 3;
}

/* Home Book Icon */
.home-book {
    position: absolute;
    bottom: 25px;
    left: 50%; /* Center horizontally */
    width: 80px;
    height: auto;
    z-index: 3;
    transform: translateX(-50%); /* Keep it centered horizontally */
}

/* Home Book Icon */
.home-book {
    position: absolute;
    bottom: 45px;
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Ensure it's centered */
    width: 80px;
    height: auto;
    z-index: 3;
}

/* Home Book Button */
.home-book-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    bottom: -10px;
    left: 50%; /* Center it horizontally */
    transform: translateX(-50%); /* Ensure it's centered */
    z-index: 3;
    transition: transform 0.3s ease, filter 0.3s ease;
}

/* Add the hover effect */
.home-book-button:hover .home-book {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 1));
    transform: translateX(-50%) scale(1.1); /* Keep it centered and scale */
}


